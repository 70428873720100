@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "font-awesome/css/font-awesome.min.css";
/*
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #222b36 inset !important;
  border-radius: initial !important;
}
*/

.centered-text {
  fill: black;
  font-size: 9px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rip {
  background-color: white;
  z-index: 9;
}

.rip {
  height: 32px;
  margin: 0 17px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAACCAYAAAB7Xa1eAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuOWwzfk4AAAAaSURBVBhXY5g7f97/2XPn/AcCBmSMQ+I/AwB2eyNBlrqzUQAAAABJRU5ErkJggg==);
  background-size: 6px 3px;
  background-repeat: repeat-x;
  background-position: center;
  position: relative;
  box-shadow: 0 1px 0 0 #fff, 0 -1px 0 0 #fff;
}
.rip:before, .rip:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border: 8px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 100%;
  pointer-events: none;
}
.rip:before {
  left: -17px;
}
.rip:after {
  transform: translate(-50%, -50%) rotate(225deg);
  right: -65px;
}

.st0{fill:#2383ac; cursor:pointer;}
.st1{fill:#979797; cursor:pointer;}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.loader {
  height: 42px;
  border-radius: 50px;
  width: 100%;
  margin-bottom: 15px;
  background: linear-gradient(to right, #f4f9fb 10%, #e6f1f5 18%, #f4f9fb 33%);
  background-size: 400% 100%;
  animation: gradient 1s linear infinite;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
@keyframes gradient {
  from {
    background-position: 0 50%;
  }
  to {
    background-position: 100% 50%;
  }
}
a {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.cu-footer a {
  text-decoration: none;
  color: #ffffff;
}
.title-line-white::before {
  display: block;
  content: "";
  background-color: #fff;
  width: 50px;
  height: 4px;
  position: absolute;
  top: -30px;
}
.title-line-white {
  position: relative;
}
.title-line::before {
  display: block;
  content: "";
  background-color: #dfa113;
  width: 50px;
  height: 4px;
  position: absolute;
  top: -30px;
}
.title-line {
  position: relative;
}
.title-line-right::before {
  display: block;
  content: "";
  background-color: #dfa113;
  width: 50px;
  height: 4px;
  position: absolute;
  top: -30px;
  right: 0;
}
.title-line-right {
  position: relative;
}
.title-line-center::before {
  display: block;
  content: "";
  background-color: #dfa113;
  width: 50px;
  height: 4px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}
.title-line-center {
  position: relative;
}
.title-line-center-white::before {
  display: block;
  content: "";
  background-color: #fff;
  width: 50px;
  height: 4px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}
.title-line-center-white {
  position: relative;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.chef-paitilla {
  border-radius: 20px;
}
#rc-anchor-container {
  width: 100% !important;
}
.chef-paitilla {
  width: 100%;
}
.cu-navmain hr {
  opacity: 0.7;
}
.nav-item.dropdown > a {
  cursor: pointer;
}
.dropdown-menu {
  display: none;
  position: absolute;
  left: 15px !important;
  top: 100% !important;
  transform: translate(0px, 0px) !important;
  min-width: 200px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 32px;
  border-radius: 16px;
  padding: 5px 10px 5px 10px;
}
.dropdown-menu a {
  display: block;
  padding: 8px;
}
.dropdown-menu a:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.nav-item {
  position: relative;
  padding-left: 20px;
}
.nav-item a {
  font-size: 13px;
  font-weight: 500;
  color: #353b44;
  text-decoration: none;
}
.nav-item a:hover {
  color: #2383ac;
}
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
.cunav {
  display: flex;
  justify-content: space-between;
}
.alice-carousel__stage-item * {
  width: 100%;
}
.list-restaurantes .logo-list-restaurante {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cu-banquetes-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  font-weight: 700;
  color: #fff;
  z-index: 99;
  font-size: 1.5rem;
}
.list-restaurantes:hover .logo-list-bg {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.logo-list-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(35, 131, 172, 0.8);
  height: 174px;
  width: 242px;
  border-radius: 16px;
  -webkit-transition: all 0.4s cubic-bezier(0.56, 0.11, 0.45, 0.95);
  transition: all 0.4s cubic-bezier(0.56, 0.11, 0.45, 0.95);
}
.iconDetails1 {
  margin: 0 auto;
  text-align: center;
  color: #2383ac;
  border-radius: 16px;
  background-color: rgba(35, 131, 172, 0.05);
  line-height: normal;
  width: fit-content;
  padding: 10px 15px;
}
.iconDetails1 svg {
  padding: 0px;
  margin: 0px;
}
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 10px !important;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.205) !important;
  color: #fff;
}
.claseDeportivaDetallesCarousel .claseDeportivaDetallesCardCarousel {
  border-radius: 16px;
  background: #2383ac;
  color: #fff !important;
  height: 100%;
}
.claseDeportivaDetallesCardCarousel .MuiTypography-root {
  color: #fff !important;
}
.claseDeportivaDetalles .claseDeportivaDetallesCard {
  background: rgba(35, 131, 172, 0.05);
  border-radius: 16px;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.claseDeportivaDetalles .claseDeportivaDetallesIcon {
  margin: 0 auto;
  text-align: center;
  color: #2383ac;
  line-height: normal;
}
.claseDeportivaDetalles .claseDeportivaDetallesCard:hover {
  background: #2383ac;
}
.claseDeportivaDetalles
  .claseDeportivaDetallesCard:hover
  .claseDeportivaDetallesIcon,
.claseDeportivaDetalles .claseDeportivaDetallesCard:hover .MuiTypography-root {
  color: #fff;
}
.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #2383ac !important;
}
.alice-carousel__dots-item:not(.__custom) {
  background-color: #d5d5d5 !important;
}
.carouselConce .alice-carousel__next-btn {
  position: absolute;
  right: -70px;
  top: calc(50% - 24px);
  transform: translateY(-50%);
}

.carouselConce .alice-carousel__prev-btn {
  position: absolute;
  left: -70px;
  top: calc(50% - 24px);
  transform: translateY(-50%);
}
.alice-carousel__next-btn {
  position: absolute;
  right: -100px;
  top: calc(50% - 24px);
  transform: translateY(-50%);
}
.alice-carousel__prev-btn {
  position: absolute;
  left: -100px;
  top: calc(50% - 24px);
  transform: translateY(-50%);
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  width: auto !important;
  cursor: pointer;
}
.carouselConce .alice-carousel__prev-btn svg,
.carouselConce .alice-carousel__next-btn svg {
  font-size: 40px;
  font-weight: lighter !important;
  border: 2px solid #2383ac;
  color: #2383ac;
  border-radius: 50px;
}
.alice-carousel__prev-btn svg,
.alice-carousel__next-btn svg {
  font-size: 50px;
  font-weight: lighter !important;
  border: 2px solid #2383ac;
  color: #2383ac;
  border-radius: 50px;
}
.chatBotBG {
  background: linear-gradient(
    100.68deg,
    #edf5f8 -24.62%,
    #47acd7 37.12%,
    #2383ac 108.21%
  );
  color: #fff;
}
.deliveryBG {
  background: linear-gradient(90deg, rgb(197 118 31) 0%, rgb(28 17 5) 100%);
  color: #fff;
}
.chatBotBG-Robot {
  text-align: right;
}
.pedidos-logo {
  text-align: right;
  position: relative;
  height: 100%;
}
.pedidos-logo img {
  position: absolute;
  width: 50%;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 10px !important;
}
.pe-home-photos,
.blog-home-photos {
  height: 100%;
  width: 100%;
  position: relative;
}
.pe-home-photos img,
.res-home-photos img {
  position: absolute;
  border-radius: 15px;
}
.pe-home-photos .img1 {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.pe-home-photos .img2 {
  left: 40%;
  bottom: 0%;
}
.pe-home-photos .img1.right {
  right: 0px;
  left: auto;
}
.pe-home-item,
.res-home-item,
.relite-home-item {
  position: relative;
}
.res-home-photos .img1 {
  top: 50%;
  right: 0;
  right: 0px;
  transform: translateY(-50%);
}
.res-home-photos .img2 {
  bottom: 8%;
  transform: translateY(-8%);
  right: 10%;
  background: rgba(35, 131, 172, 0.6);
  backdrop-filter: blur(5px);
  border-radius: 16px;
  filter: drop-shadow(0px 14px 16px rgba(50, 50, 71, 0.08))
    drop-shadow(0px 32px 32px rgba(50, 50, 71, 0.08));
}
.relite-home-item {
  text-align: center;
}
.relite-home-item .img1 {
  position: absolute;
  left: 50%;
  top: 58%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 15px;
}
.relite-home-item .img2 {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 58%;
  transform: translate(-50%, -50%);
}
.blog-home-photos .img1 {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  position: absolute;
}
.blog-home-photos {
  height: 100%;
}
.cu-sociallist {
  padding-top: 15px;
}
.cu-sociallist .cu-sociallist-wrap {
  display: inline-block;
}
.cu-sociallist a {
  width: 30px;
  height: 30px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 29px;
  margin-right: 10px;
}
.cu-sociallist a:hover {
  opacity: 0.6;
}
.cu-sociallist-slider a {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}
.cu-sociallist a:hover {
  opacity: 0.6;
}
.slick-slide img {
  width: 100%;
}
.cu-homeSlider {
  width: 50%;
  float: right;
}
.cu-homeSlider-item {
  border-radius: 0px 0px 0px 150px;
}
.cu-homeSlider-wrap {
  height: 600px;
  position: relative;
  overflow: hidden;
}
.cu-homeSlider-overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 70%
  );
}
.cu-bg-historia {
  background-image: url("./images/bg-historia.jpg");
  background-position: top center;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  border: 2px solid #2383ac;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #2383ac !important;
  padding: 0px;
  margin: 0px;
  text-align: center;
  line-height: normal;
  font-weight: bold;
}
.cuposStatus {
  color: #4bbb56;
  font-weight: 700;
}
.cuposStatus svg {
  position: absolute;
  top: 2px;
}
.cuposStatus span {
  padding-left: 20px;
}
.cuposStatus > div {
  position: relative;
}
.cu-cuposStatus-no {
  color: #b83c3c;
}
.cu-logo-elite {
  text-align: center;
}
.cu-logo-elite img {
  display: inline-block;
  height: 100px;
}
.MuiButton-root:hover .MuiButton-endIcon {
  transform: translateX(5px);
  transition: all 200ms;
}
.alice-carousel__stage-item img {
  border-radius: 16px;
}
.cu-title-header {
  text-align: center;
}
.cu-homeSlider-wrap-content {
  position: absolute;
  z-index: 5;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.cu-homeSlider-content {
  padding: 30px;
  position: absolute;
  width: 43%;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 16px;
  text-align: center;
}
.Mui-disabled.MuiPickersDay-root {
  background-color: #f3f3f3;
}
.css-1famf0x-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  background-color: #f3f3f3;
}
.cunav-user-icon svg {
  position: absolute;
  right: 0px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.cunav-user-icon {
  padding-right: 30px;
}
.cu-homeSlider-logo {
  height: 200px;
  display: inline-block;
}
.cu-homeSlider-logo-wrap {
  text-align: center;
  width: 100%;
  padding-bottom: 60px;
}
.cu-list-historia {
  text-align: center;
  display: block;
  padding: 0;
}
.cu-list-historia li {
  text-align: center;
  display: block;
  padding: 0;
}
.cu-logo-restaurante-header {
  height: 80px;
}
.cu-logo-restaurante-header.time-out {
  height: 50px;
}
.cu-logo-restaurante-header.terraza-miramar {
  height: 65px;
}
.cu-logo-restaurante-header.almirante {
  height: 100px;
}
.cu-logo-restaurante-header.snack-bar {
  height: 100px;
}
.logo-list-restaurante {
  width: 25%;
}
.logo-list-restaurante.snack-bar {
  width: 16%;
}
.logo-list-restaurante.over-time {
  width: 10%;
}
.logo-list-restaurante.las-arcadas {
  width: 10%;
}
.cu-cover-event {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
}
.cu-wrapiframe-article iframe {
  height: 650px !important;
}
/*.fullblur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
} */
.terminos-y-condiciones {
  display: inline-block;
  font-size: 14px;
  margin-left: -10px;
}
.terminos-y-condiciones2 {
  display: inline-block;
  font-size: 14px;
}
.cu-homeSlider-content {
}
.uploader__container {
  width: 100% !important;
}
.uploader__placeholder {
  border-radius: 15px;
}
.cardicon {
  font-size: 70px !important;
}
.uploader__btn_wrapper {
  right: 10px !important;
  top: 10px !important;
  z-index: 1;
}
.uploader__file_input_label svg {
  width: 100% !important;
}
.fileContainer {
  box-shadow: none !important;
  background-color: #f9f9f9 !important;
}
.fileContainer .uploadPictureContainer {
  width: 70% !important;
  margin: 0% !important;
  padding: 0px !important;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
  border: 1px solid #d0dbe4;
  position: relative;
}
.fileContainer .deleteImage {
  background: #2383ac !important;
  border-radius: 50% !important;
  font-size: 17px !important;
}
.fileUploader {
  overflow: hidden;
}
.fileContainer .chooseFileButton {
  font-weight: 700 !important;
  font-size: 14px;
}
.presidente-photo img {
  border-radius: 20px;
}
.reciprocidad a {
  text-decoration: none;
  color: #2383ac;
  margin-bottom: 10px;
  display: block;
}
.defaulter {
  display: block;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  background-color: #f49c1a;
  font-size: 13px;
  color: #061d26;
  font-weight: 500;
  z-index: -1;
}
.communique {
  display: block;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
  background-color: #232553;
  font-size: 13px;
  color: #ffffff;
  font-weight: 500;
  z-index: -1;
}
.defaulter svg {
  color: #124256;
}
.directorphotomsg {
  width: 38%;
  float: left;
  border-radius: 20px;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-top: 20px;
  display: block;
}
.caunter_containter #video {
  border: 3px solid #2383ac;
  background-color: #000;
  border-radius: 15px;
}
.fslightbox-container {
  background: rgba(0, 0, 0, 0.63) !important;
}
#botmaker-wrapper {
  bottom: 2% !important;
  overflow: visible !important;
}
.junta-photo {
  width: 100%;
  border-radius: 20px;
}
.termsandconditions {
  text-align: justify;
}
.termsandconditions a {
  text-decoration: none;
  color: #2383ac;
}
.termsandconditions .paddingtyc {
  padding-left: 50px;
  list-style-type: circle;
  list-style: inside;
  display: list-item;
  line-height: normal;
}
.cardexample {
  width: 70%;
}
.tox-dialog {
  z-index: 150000 !important;
}
.file-list {
  margin: 0px 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  width: calc(10% - 20px);
  height: 100px;
  background-size: cover;
  background-position: center center;
  margin: 10px;
  cursor: move;
  transition: all 0.2s linear;
  position: relative;
  border-radius: 8px;
}

.datepicker-club .MuiIconButton-sizeMedium {
 bottom: 60px;
}
/* .MuiFormHelperText-root {
  display: none;
} */
.file-img {
  width: 100%;
  height: 100%;
}
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}
.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.fc .fc-list-event-title a {
  font-size: 13px;
  font-weight: 500;
}
.has-info span {
  display: inline-block;
  padding-left: 10px;
}
.contest-logo {
  width: 50%;
}
@media only screen and (max-width: 1280px) {
  .clubunion-movil-logo-wrap {
    text-align: center;
    display: block !important;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      0deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(35, 131, 172, 0.11528361344537819) 100%
    );
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .clubunion-movil-logo-wrap img {
    display: inline-block;
  }
  .clubunion-movil-logo {
    width: 10%;
  }
  .movil-pedidos-logo {
    width: 40%;
  }

  .presidente-photo {
    order: -1;
  }
  .logo-navbar {
    flex-grow: 1;
    justify-content: end;
    align-items: center;
    text-align: right;
  }

  .title-line-right {
    text-align: center;
  }
  .restaurantBlock p {
    text-align: justify;
  }
  .restaurantBlock button {
    text-align: justify;
  }
  .chatBotBG-Robot {
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .contest-logo {
    width: 70%;
  }
  #botmaker-wrapper {
    display: none !important;
  }
  .title-line-white::before {
    display: none;
  }
  .logo-list-restaurante {
    width: 50%;
  }
  .logo-list-restaurante.over-time {
    width: 40%;
  }
  .clubunion-movil-logo {
    width: 20%;
  }
  .movil-pedidos-logo {
    width: 100%;
  }
  .chatBotBG-Robot img {
    width: 100%;
  }
  .pedidos-logo {
    text-align: center;
    position: relative;
    height: 100%;
  }

  .termsandconditions .paddingtyc {
    line-height: normal;
  }

  .directorphotomsg {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .cu-banquetes-title {
    text-align: center;
  }
  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    display: none !important;
  }

  .relite-home-item .img1 {
    position: relative;
    width: 100%;
    border-radius: 20px;
  }
  .relite-home-item .img2 {
    z-index: 0;
    display: none;
  }
  .cu-homeSlider {
    display: none;
  }

  .pe-home-photos .img1 {
    position: relative;
    width: 100%;
    top: auto;
    left: 0;
    transform: none;
  }
  .caunter_containter {
    display: block;
  }
  .res-home-item {
    display: none;
  }
  .cu-homeSlider-content {
    padding: 20px;
    position: relative;
    width: 100%;
    text-align: justify;
  }
  .cu-homeSlider-content h3 {
    text-align: center;
  }
  .cu-homeSlider {
    width: 100%;
    float: none;
    opacity: 0.2;
  }
}
